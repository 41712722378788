

.container{
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 2rem;
  background-color: #00000083;
 
  
}
.box{
  max-width: 900px;
  margin: 2px auto;
  
  padding-bottom: 1rem;
}
.bg{

  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  background-attachment: fixed;
  
  
}

