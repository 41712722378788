.title {
    font-size: 23px;
    font-weight: 700;
    color: #f5f5f5;
}

.daily-item {
    background-color: #f4e9e9b9;
    border-radius: 15px;
    height: 40px;
    margin: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 20px;
}

.icon-small {
    width: 40px;
}

.day {
    color: #0c0c0c;
    flex: 1 1;
    font-weight: 600;
    margin-left: 15px;
}

.description {
    flex: 1 1;
    margin-right: 15px;
    text-align: right;
}

.min-max {
    color: #5b0303;
}

.daily-details-grid {
    grid-row-gap: 0;
    grid-column-gap: 15px;
    row-gap: 0;
    column-gap: 15px;
    display: grid;
    flex: 1 1;
    grid-template-columns: auto auto;
    padding: 5px 15px;
}

.daily-details-grid-item {
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
}

.daily-details-grid-item label:first-child {
    color: #fefcfc;
}

.daily-details-grid-item label :last-child {
    color: #0d0d0d;
    font-weight: 200;

}

@media only screen and (max-width: 600px) {

    .daily-details-grid {
        grid-row-gap: 1rem;
        grid-column-gap: 30px;
        row-gap: 0;
        column-gap: 30px;
        display: grid;
        flex: 1 1;
        grid-template-columns: auto auto;
        padding: 5px 15px;
    }
    
}