.nav{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
    flex-direction: row;
    gap: 0.5rem;
    
}

.btn{
    width: 80px;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.671);
    color: antiquewhite;
    border: none;
    border-radius: 5px;
}